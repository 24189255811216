.search {
    display: flex;

    input[type="text"] {
        width: 200px;
        height: 38px;
        padding: 0 1rem;
        font-size: 1rem;
        background: var(--white);
        box-shadow: 0 2px 1px 0 rgba(0,0,0,0.07);
        border-radius: 6px 0 0 6px;
    }

    button {
        width: 38px;
        height: 38px;
        font-size: 1rem;
        background: var(--secondary-color);
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--white);
        border-radius: 0 6px 6px 0;

        &:hover {
            background: darken(#39AC4F, 5%);
        }
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        margin-bottom: 1.5rem;
    }
}