.btn-small {
    margin-left: 1rem;
    padding: 0 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    font-size: 1rem;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.07);
    border-radius: 100px;
    cursor: pointer;
    color: var(--white);

    &:hover {
        background-color: darken(#39AC4F, 10%);
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .btn-small-icon {
        margin-right: 0.5rem;
        font-size: 1.4rem;
    }

    &.btn-small-alt {
        border: 1px solid var(--aux-color);
        background: none;

        &:hover {
            background-color: darken(#1D268E, 10%);
        }
    }
}