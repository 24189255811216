input[type="text"], input[type="password"] {
    padding: 1rem 2rem;
    display: block;
    font-size: 1.2rem;
    border: none;
    outline: none;
    background: var(--white);
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.10);
    border-radius: 100px;
    color: #979797;

    &:focus {
        background: #C4C9FF;
        color: var(--black);
    }

    &.input-error {
        background: rgb(250, 221, 226);
    }

    &:disabled {
        opacity: 0.5;
        color: var(--grey);
    }
}

.select-input.input-error > div {
    background: rgb(250, 221, 226);
}

.field-alt {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        flex: 0.3;
        display: block;
        font-family: var(--secondaryFontAlt);
        color: var(--grey);
    }

    .field-error {
        display: none !important;
    }

    input[type="text"], input[type="password"], input[type="number"], input[type="email"], .file-input-button {
        flex: 0.6;
        padding: 0.5rem 1rem;
        background: var(--white);
        border: 1px solid var(--aux-color);
        font-size: 1rem;
        box-shadow: 0 4px 7px 0 rgba(0,0,0,0.10);
        border-radius: 5px;

        &:focus {
            background: lighten(#C4C9FF, 10%);
            color: var(--black);
        }

        &.input-error {
            background: rgb(250, 221, 226);
        }
    }

    .file-input-button {
        cursor: pointer;
        color: #979797;
        text-align: left;

        &:hover {
            background: lighten(#C4C9FF, 10%);
            color: var(--black);
        }

        &.input-error {
            background: rgb(250, 221, 226);
        }
        
    }

    .file-input {
        display: none;
    }

    // Breakpoints

    @media screen and (max-width: 480px) {
        display: block;

        label {
            margin-bottom: 0.5rem;
        }

        input[type="text"], input[type="password"], input[type="number"], input[type="email"], .file-input-button {
            width: 90%;
        }
    }
}

.field-date {
    display: block;

    label {
        width: 190px;
        display: inline-block;
    }

    .react-datepicker-wrapper {
        width: 320px;
        display: inline-block;
    }

    .react-datepicker__input-container  {

    }

    input[type="text"] {
        width: 288px;
    }

    .react-datepicker__month-text--keyboard-selected {
        background-color: var(--primary-color);
    }
    
    .react-datepicker__month .react-datepicker__month-text {
        margin-bottom: 0.25rem;
        font-size: 0.95rem;
        text-transform: capitalize;
    }
}

.select-input {
    flex: 0.628;
}

.select-input > div {
    padding: 0.1rem 0.4rem;
    background: var(--white);
    font-size: 1rem;
    border: 1px solid var(--aux-color);
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.10);
    border-radius: 5px;
}

.field span {
    margin: 1rem 0 1rem 0;
    display: block;
    font-size: 0.9rem;
    color: pink;
}

.form-error-message {
    margin: 0 0 2rem 0;
    padding: 0.5rem 1rem;
    background: rgb(250, 221, 226);
    border-radius: 10px;
    border: 1px dotted var(--danger-color);
}

.form-actions {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 5px solid #EBECED;
    display: flex;
    justify-content: space-around;

    .btn {
        flex: 0.4;
    }

    // Breakpoints

    @media screen and (max-width: 480px) {
        margin-top: 2rem;
        display: block;

        .btn {
            width: 100%;
            margin-bottom: 1rem;
            display: block;
        }
    }
}


// Multi Select Input 

.field-multi-select {
    label {
        margin-bottom: 1rem;
        display: block;
        font-family: var(--secondaryFontAlt);
        color: var(--grey);
    }

    .search {
        input[type="text"] {
            flex: 1;
            border: 1px solid #CCCCCC;
            border-radius: 10px;
        }

        button {
            display: none;
        }
    }

    .field-multi-select-wrapper {
        margin-top: 1.5rem;
        height: 200px;
        overflow-y: scroll;
    }

    .field-multi-select-heading {
        margin: 0 0 1rem 0;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: var(--light-grey);
    }

    .field-multi-select-option {
        width: 48%;
        margin: 0.25rem;
        padding: 0.25rem;
        background-color: rgba(#9FA3D9, 0.25);
        font-size: 1rem;
        border: none;
        border-radius: 10px;
        cursor: pointer;

        &:hover, &.field-multi-select-option-active {
            background-color: rgba(#9FA3D9, 0.75);
        }

        &:disabled {
            opacity: 0.95;
            // pointer-events: none;
        }
    }

    .field-multi-select-active-group {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px dotted var(--aux-color);
    }

}

// Date Range picker

.date-range-picker {
    display: flex;
    align-items: center;

    .react-datepicker-wrapper {
        margin: 0 1rem;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: var(--primary-color);
    }
    
    .react-datepicker__current-month {
        margin-bottom: 0.25rem;
        font-size: 0.95rem;
        text-transform: capitalize;
    }

    input {
        padding: 0.5rem;
        border-radius: 10px;
        background-color: white;
        text-align: center;
        color: black;
        font-size: 1rem;
        
        &:focus {
            background-color: white;
        }
    }

    .date-range-picker-reset-button {
        padding: 0.5rem 0.75rem;
        border: none;
        color: white;
        background-color: var(--danger-color);
        font-size: 1rem;
        border-radius: 100px;
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
            background-color: rgba(black,0.2);
        }
    }
}