.btn {
    padding: 1rem 4rem;
    background: var(--secondary-color);
    border-radius: 100px;
    border: none;
    font-family: var(--secondaryFont);
    font-size: 21px;
    color: var(--white);
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;

    &:hover {
        background: darken(#39AC4F, 5%);
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    // Breakpoints

    @media screen and (max-width: 480px) {
        padding: 0.75rem 2rem;
        font-size: 18px;
    }
}

.btn-secondary {
    background: #B9B9B9;

    &:hover {
        background: darken(#B9B9B9, 5%);
    }
}