.form-steps {
    margin-bottom: 2rem;
    display: flex;

    .form-step {
        padding: 0.2rem;
        flex: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(#9FA3D9,0.25);

        span {
            width: 30px;
            height: 30px;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#9FA3D9,0.5);
            border-radius: 100%;
        }

        p {
            font-size: 0.8rem;
            text-transform: uppercase;
        }

        &:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        &:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        &.active {
            background-color: var(--primary-color);
            color: var(--white);

            span {
                background-color: darken(#9FA3D9, 15%);
            }
        }
    }

    // Breakpoints

    @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
        display: block;

        .form-step {
            padding: 0 0 0 1rem;
            justify-content: flex-start;
            border-radius: 15px;

            p {
                font-size: 0.7rem;
            }
        }
    }
}