.toggle-account-issues-button {
    height: 40px;
    margin-right: 1rem;
    padding: 0 1rem;
    background: rgb(250, 221, 226);
    border-radius: 10px;
    font-size: 1rem;
    line-height: 0;
    color: var(--light-grey);
    border: 1px dotted var(--danger-color); 

    cursor: pointer;

    .toggle-account-issues-icon {
        margin-right: 0.25rem;
        font-size: 1.1rem;
        opacity: 0.5;
        color: var(--danger-color);
    }

    &.toggle-issues-active {
        color: var(--black);

        .toggle-account-issues-icon {
            opacity: 1;
        }
    }

    &:hover {
        color: var(--black);

        .toggle-account-issues-icon {
            opacity: 1;
        }
    }
}