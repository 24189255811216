.download-report-button-wrapper {
    margin: 4rem auto 0 auto;
    text-align: center;

    .download-report-button-heading {
        color: var(--neutral-color);
    }

    .download-report-button {
        padding: 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 1.5rem;
        border-radius: 25px;
        border: 1px solid rgba(#9FA3D9, 0.75);
        color: var(--primary-color);

        &:hover {
            background-color: rgba(#9FA3D9, 0.15);
        }

        span {
            margin-top: 0.25rem;
            display: block;
            font-size: 1rem;
            color: var(--aux-color);
        }

        .download-report-button-icon {
            margin-right: 1.5rem;
            font-size: 3.5rem;
        }
    }

}