.user-card {
    margin-bottom: 1rem;
    padding: 0.2rem 1.2rem;
    background-color: #2A3295;
    border-radius: 10px;
    color: var(--white);

    &:hover {
        background-color: lighten(#2A3295, 5%);
    }

    .user-card-content {
        display: flex;
        align-items: center;
    }

    .user-card-edit-button, .user-card-edit-porfolio-button {
        padding: 0;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        color: #DBDDDF;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .user-card-edit-porfolio-button {
        margin-right: 1.5rem;
    }

    .user-card-name {
        flex: 0.6;
        cursor: pointer;

        h2 {
            margin-bottom: 0;
            font-family: var(--secondaryFontAlt);
            font-size: 1.2rem;
            letter-spacing: 0;
            line-height: 14px;
        }

        p {
            margin: 0;
            margin-left: 1.5rem;
            margin-bottom: 0.25rem;
            font-size: 0.875rem;
            opacity: 0.75;
        }

        .user-card-toggle-icon {
            margin-right: 0.5rem;
            opacity: 0.5;
            font-size: 1.5rem;
            vertical-align: middle;
        }
    }

    .user-card-email {
        flex: 0.5;
    }

    .user-card-role {
        flex: 0.25;
    }

    .user-card-status {
        flex: 0.17;
    }

    .user-card-info {
        padding: 1rem 0;
        border-top: 1px dotted var(--aux-color);

        .clients-entries-heading {
            margin-left: 0.25rem;
            padding-bottom: 0.25rem;
            text-transform: uppercase;
            font-size: 0.75rem;
            border-bottom: 1px dotted var(--aux-color);
        }

        .clients-entry {
            margin: 0.25rem;
            padding: 0.5rem 1rem;
            display: inline-block;
            border-radius: 10px;
            background-color: var(--primary-dark-color);
        }

        .client-entries-permissions {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .clients-entry {
                width: 29%;
                display: block;
            }
        }
    }

    // Breakpoints

    @media screen and (max-width: 960px) {
        flex-direction: column;

        .user-card-edit-porfolio-button, button {
            margin-right: 0;
            margin: 0.5rem 0;
        }
    }
}