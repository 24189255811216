.upload-balance-error-message {
    .error-message-heading {
        display: block;
        margin-bottom: 1rem;
    }

    .error-message-row {
        margin: 0.5rem 0 0.25rem 0;
        padding: 0.75rem 0.5rem;
        display: block;
        background-color: var(--danger-color);
        color: var(--white);
        font-size: 0.9rem;
        border-radius: 10px
    }

    .row-number {
        padding: 0.2rem 0.5rem;
        background-color: var(--white);
        color: var(--danger-color);
        font-size: 0.8rem;
        font-weight: bold;
        border-radius: 10px
    }
}