/* VARIABLES, MIXINS & FUNCTIONS */
// =======================================================================================

// Variables =======================================================================

:root {
    // Theme Colors

    --primary-color:         #2A34A9;
    --primary-dark-color:    #1D268E;
    --secondary-color:       #39AC4F;
    --aux-color:             #9FA3D9;
    --neutral-color:         #6EA4CA;
    --success-color:         #39AC4F;
    --danger-color:          #BE3F32;

    // Base colors

    --grey:                 #4A4A4A;
    --light-grey:           #818181;
    --white:                #FFFFFF;
    --black:                #000000;

    // Gradients

    --main-gradient: linear-gradient(-26deg, #5464D4 5%, #2A34A9 100%);

    // Typography

    --primaryFont:     'Open Sans', 'Arial', sans-serif;
    --secondaryFont:   'FuturaBold', sans-serif;
    --secondaryFontAlt:    'FuturaHeavy', sans-serif;

}