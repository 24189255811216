.table-list {

    .table-entry {

        .log-customer {
            width: 20%;
        }

        .log-message {
            width: 50%;
        }

        .log-date {
            width: 10%;
        }

        .log-message-changes {
            display: flex;
            justify-content: space-between;

            p {
                margin: 0;
                color: var(--aux-color);
            }
        }

    }
}