.dashboard-button {
    width: 100%;
    margin: 2rem 1rem;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    border-bottom: 2px solid #3D45A0;
    cursor: pointer;
    text-decoration: none;
    font-size: inherit;
    color: var(--white);

    .dashboard-button-info {
        flex: 1;

        p {
            font-size: 1rem;
        }
    }

    .dashboard-button-icon {
        flex: 0.2;
        font-size: 3rem;
    }

    &:hover {
        border-bottom-color: lighten(#3D45A0, 15%);
    }
}