.reports-buttons-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .reports-button {
        width: 48%;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.5rem;
        border-radius: 15px;
        border: 2px solid var(--aux-color);
        background: none;
        color: var(--white);
        cursor: pointer;

        .reports-button-icon {
            margin-right: 1.5rem;
            font-size: 3.5rem;
            color: var(--aux-color);
        }

        &:hover .reports-button-icon {
            color: var(--white);
        }

    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        display: block;

        .reports-button {
            width: 100%;
            padding: 0.5rem;
            font-size: 1.25rem;

            .reports-button-icon {
                font-size: 2rem;
            }
        }
    }
}