.upload-records-button {
    padding: 0 10%;
    text-align: center;
    border-right: 2px solid #3D45A0;

    button {
        margin: 1rem 0;
        padding: 7% 20%;
        background-color: #2A3295;
        border: 2px solid var(--aux-color);
        border-radius: 50px;
        cursor: pointer;
        color: var(--white);

        .upload-records-icon {
            margin-bottom: 1.25rem;
            font-size: 5rem;
        }

        p {
            margin: 0;
            font-weight: bold;
            font-size: 1.3rem;
        }

        &:hover {
            background-color: darken(#2A3295, 5%);
        }
    }

    .upload-records-formats {
        font-size: 0.8rem;
    }
}