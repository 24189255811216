.clients-list-style-toggle {
    margin-left: 1rem;
    border: 1px solid var(--neutral-color);
    border-radius: 5px;

    button {
        padding: 0.25rem 0.5rem;
        background-color: var(--neutral-color);
        border: none;
        color: var(--white);
        font-size: 1.25rem;
        cursor: pointer;

        &:first-of-type {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:disabled {
            background-color: rgba(#6EA4CA, 0.5);
            color: rgba(white, 0.75);
        }
    }
}