.client-card-simple {
    margin-bottom: 3rem;
    padding: 0.5rem 1.2rem 0.8rem 1.2rem;
    background-color: var(--white);
    border-radius: 10px;
    color: var(--light-grey);

    .client-card-link {
        display: flex;
        text-decoration: none;
        color: inherit;
    }

    .client-card-details {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;

        h2 {
            font-family: var(--secondaryFontAlt);
            font-size: 1.5rem;
            color: var(--grey);
            letter-spacing: 0;
            line-height: 31px;
        }
    }

    .client-card-logo {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        background-color: var(--aux-color);
        border-radius: 5px;

        &.client-card-logo-clear {
            background: none;
        }
    }

    .client-card-balance {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid #E8E9F6;
    }

    .client-card-balance-entry {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        p {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            margin-left: 1rem;
            font-size: 0.9rem;
            font-weight: bold;
        }
    }
}