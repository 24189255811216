.table-list {
    margin-bottom: 2rem;
    color: var(--white);

    table {
        width: 100%;
        border-collapse: collapse

        th {
            padding: 0.2rem 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            border-bottom: 1px solid rgba(#FFFFFF, 0.25);
        }
    }

    .table-entry {
        td {
            padding: 0.2rem 1.2rem;
            border-bottom: 1px solid rgba(#9FA3D9,0.24);

            &:first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

        &:hover {
            background-color: lighten(#2A3295, 5%);
        }

        .table-entry-button {
            margin: 0 0.25rem;
            background: none;
            border: none;
            cursor: pointer;
            color: var(--white);

            .table-entry-button-icon {
                font-size: 1.5rem;
                color: #DBDDDF;
                opacity: 0.5; 
                 
                &:hover {
                    opacity: 1;
                }
            }
        }

        .table-entry-link {
            text-decoration: none;
            color: var(--white);

            .table-entry-link-icon {
                font-size: 1.5rem;
                color: #DBDDDF;
                opacity: 0.5; 
                 
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}


// Clint Account List

.client-accounts-list {
    // max-height: 80vh;
    // overflow: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: var(--white);
    // overflow: hidden;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    color: var(--grey);

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
        border-radius: 10px;

        thead, tfoot {
            position: sticky;
        }

        thead {
            inset-block-start: 0;
        }

        tfoot {
            inset-block-end: 0;
        }

        th {
            background-color: var(--white);
            padding: 0.25rem 1rem;
            padding-bottom: 0.5rem;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            color: var(--primary-color);
            border-bottom: 3px solid rgba(#9FA3D9,0.24);
            // position: sticky;
            // top: 0;

            &:first-of-type {
                border-top-left-radius: 10px;
            }

            &:last-of-type {
                border-top-right-radius: 10px;
            }
        }

        .list-header-balance {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin: 0;
                text-align: center;
            }

            .btn-account-balance {
                color: rgba(#9FA3D9, 0.4);
                font-size: 1.6rem;
                background: none;
                border: none;
                padding: 0;
                cursor: pointer;

                &:disabled {
                    opacity: 0.5;
                    cursor: auto;
                }

                &:hover:enabled {
                    color: var(--primary-color);
                }
            }
        }
    }

    &.client-accounts-list-expanded {
        overflow: scroll;

        table {
            position: sticky;
            top: 0;
        }
    }

    .client-account-entry {
        td {
            padding: 0.25rem 1rem;
            border-bottom: 1px solid rgba(#9FA3D9,0.24);
        }

        &:hover {
            background-color: rgba(#9FA3D9,0.1);
        }

        p {
            margin: 0;
            padding-right: 0.5rem;
            font-size: 0.95rem;
        }

        .account-name {

            .account-name-inner {
                display: flex;
                align-items: center;
            }

            p {
                margin-bottom: 0.5rem;
            }

            strong {
                font-size: 1rem;
            }

            span {
                font-weight: bold;
                color: var(--neutral-color);
            }

            .show-account-issues-button {
                margin: 0;
                margin-right: 1rem;
                padding: 0;
                font-size: 1.2rem;
                border: none;
                background: none;
                cursor: pointer;
                color: var(--danger-color);
            }
        }

        .account-balance {
            min-width: 120px;
            text-align: center;
            background-color: rgba(#6EA4CA, 0.18);

            .negative-balance {
                color: var(--danger-color);
            }

            &.account-balance-cashflow {
                background-color: rgba(#9FA3D9, 0.3);
                color: #1D268E;

                .negative-cashflow {
                    color: var(--danger-color);
                }
            }
        }

        .account-edit {
            button {
                display: block;
                background: none;
                border: none;
                cursor: pointer;
                color: var(--aux-color);
                text-align: center;
            }

            .btn-edit-account-entry-icon {
                font-size: 1.2rem;
            }
        }

        .account-issues-list {
            margin-left: 2rem;
            padding: 0 1.75rem;
            background: rgb(250, 221, 226);
            border-radius: 10px;
            color: var(--light-grey);

            li {
                font-size: 0.9rem;
                padding: 0.25rem 0;
            }
        }
    }

    .client-accounts-reconciliation {
        font-size: 1.1rem;
        font-weight: bold;
        background-color: #D0EBD5;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        
        td {
            padding: 1rem;
        }

        .reconciliation-balance {
            text-align: center;
            color: var(--success-color);
        }

        &.reconcicliation-error {
            background-color: rgb(250, 221, 226);

            .reconciliation-balance {
                color: var(--danger-color);
            }
        }
    }
}