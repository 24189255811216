.settings-block {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255,255,255,0.5);
}

.settings-terms-card {
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem 0.8rem 1.2rem;
    background-color: var(--white);
    border-radius: 10px;
    color: var(--light-grey);
    font-size: 1.1rem;

    strong {
        color: var(--primary-color);
    }

    .btn {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 1.2rem;
    }
}