.client-account-list-tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        padding: 0.5rem;
        flex: 1;
        // background-color: rgba(0,0,0,0.1);
        background: none;
        border: 2px solid var(--neutral-color);
        outline: none;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        color: #E3E3E3;

        &:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }

        &.client-account-list-tab-active {
            background-color: var(--neutral-color);
            color: var(--white);
        }
    }
}