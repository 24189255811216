.main-nav {
    padding-top: 2rem;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        // border: 1px solid red;
    }

    li {
        margin-bottom: 1rem;
        padding: 0 2rem;
        position: relative;
        text-align: center;
    }

    a {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: var(--primary-dark-color);
        text-decoration: none;
        color: var(--white);
        font-size: 1.5rem;

        svg {
            opacity: 0.7;
            position: relative;
            z-index: 3;
        }

        span {
            padding: 0.25rem 0.5rem 0.25rem 1rem;
            display: none;
            position: absolute;
            left: 70px;
            font-size: 1rem;
            border-radius: 10px;
            background-color: var(--primary-dark-color);
        }

        &.active, &:hover {

            &::before {
                width: 8px;
                height: 50px;
                position: absolute;
                left: 0;
                content: '';
                background-color: #666DC3;
            }

            svg {
                opacity: 1;
            }
        }

        &:hover {
            span {
                display: inline-block;
                animation-duration: 0.25s;
                animation-name: slideout;
            }
        }
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        padding-top: 0;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            padding: 0 1rem;
        }

        a {
            &.active, &:hover {
    
                &::before {
                    width: 50px;
                    height: 6px;
                    left: auto;
                    bottom: -15px;
                }
    
            }

            &:hover {
                span {
                    display: none;
                }
            }
        }
    }
}

@keyframes slideout {
    from { margin-left : -40px; }
    to   { margin-left : 0; }
}