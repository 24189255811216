.permissions-list {
    padding-top: 0.25rem;

    p {
        font-weight: bold;
    }

    ul {
        max-height: 300px;
        padding: 0.25rem 0;
        border-top: 1px solid var(--aux-color);
        border-bottom: 1px solid var(--aux-color);
        list-style: none;
        overflow-y: scroll;
    }
    
    li {
        margin-bottom: 1rem;
    }

    button {
        display: flex;
        padding: 0.25rem;
        background: none;
        border: none;
        font-size: 1.1rem;
        cursor: pointer;
        text-align: left;
        color: var(--light-grey);

        .permissions-list-icon {
            margin-right: 0.5rem;
            font-size: 1.4rem;
            vertical-align: middle;
            color: var(--aux-color);
        }

        &:hover {
            color: var(--primary-color);
        }

        &.permissions-list-selected {
            color: var(--primary-color);

            .permissions-list-icon {
                color: var(--primary-color);
            }
        }

    }
}