.alert-message {
    margin: 0 0 2rem 0.5rem;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    color: var(--black);
    background: rgb(221, 250, 230);
    border: 1px dotted var(--success-color);

    p {
        margin: 0;
    }

    .alert-message-icon {
        margin-right: 0.25rem;
        font-size: 1.5rem;
        vertical-align: middle;
        color: var(--success-color);
    }

    &.alert-danger {
        background: rgb(250, 221, 226);
        border: 1px dotted var(--danger-color);

        .alert-message-icon {
            color: var(--danger-color);
        }
    }
}