body {
    margin: 0;
    background-image: var(--main-gradient);
    background-repeat: no-repeat;
    min-height: 100vh;
    font-family: var(--primaryFont)
}

// Tables =====================================================================================

table {
    // Breakpoints

    @media screen and (max-width: 600px) {
      thead { display: none; }

      tr {
        display: block;
      }

      tr td:first-child {
        font-weight: bold;
      }

      tbody td {
        width: auto !important;
        display: block;
        text-align: center;
      }

      tbody td:before {
        content: attr(data-th);
        display: block;
        text-align: center;
      }
    }  

}