.section-button {
    width: 100%;
    margin: 0 1rem 1rem 1rem;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #3D45A0;
    text-decoration: none;
    color: var(--white);

    h2 {
        flex: 1;
    }

    .section-button-icon {
        margin-right: 1rem;
        font-size: 1.5rem;
        opacity: 0.5;
    }

    &:hover {
        border-bottom-color: lighten(#3D45A0, 15%);

        .section-button-icon {
            opacity: 1;
        }
    }
}