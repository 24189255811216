.pagination {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        background: none;
        border: none;
        outline: none;
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--neutral-color);

        &:disabled {
            opacity: 0.5;
            cursor: initial;
        }
    }

    p {
        padding: 0 0.25rem;
        font-weight: bold;
        color: var(--white);
        letter-spacing: 0;
        letter-spacing: 0.2rem;
        text-align: center;
    }
}