@font-face {
    font-family: 'FuturaBold';
    src: url('../f/futuralt-bold-webfont.woff2') format('woff2'),
         url('../f/futuralt-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaHeavy';
    src: url('../f/futuralt-heavy-webfont.woff2') format('woff2'),
         url('../f/futuralt-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}