@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}

.modal {
    padding-top: 2%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(0,0,0,0.5);

    .modal-inner {
        width: 40%;
        max-width: 600px;
        max-height: 95%;
        margin: 0 auto;
        overflow-x: scroll;
        position: relative;
        background-color: var(--white);
        border-radius: 10px;
        animation-name: animatetop;
        animation-duration: 0.4s;
    }

    .modal-heading {
        margin-bottom: 2rem;
        padding: 1rem 2rem;
        background-image: var(--main-gradient);
        border-radius: 10px 10px 0 0;

        h1 {
            margin: 0;
            font-family: var(--secondaryFont);
            font-size: 26px;
            color: var(--white);
            letter-spacing: 0;
        }
    }

    .modal-content {
        margin: 0 2rem 0 2rem;
        padding-bottom: 2rem;
    }

    // Breakpoints

    @media screen and (max-width: 960px) {
        .modal-inner {
            width: 80%;
            max-width: none;
        }

        .modal-heading {
            margin-bottom: 1rem;
            padding: 1rem;
    
            h1 {
                font-size: 21px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        padding-top: 0;

        .modal-inner {
            width: 100%;
            max-height: 100%;
        }

        .modal-heading {
            h1 {
                font-size: 18px;
            }
        }
    }
}