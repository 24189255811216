.no-entries-message {
  margin-top: 15%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  button {
    margin-top: 1rem;
    padding: 1rem 2rem;
    background: none;
    border: 1px solid var(--white);
    border-radius: 100px;
    color: var(--white);
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
  }

  .no-entries-message-icon {
    font-size: 5rem;
    opacity: 0.5;
  }
}