.account-utilities {
    // margin-right: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0.17;
    color: var(--white);

    .account-utilities-menu {
        display: flex;
    }

    .account-utilities-notifications {
        width: 35px;
        margin-right: 1.5rem;
        position: relative;
    }

    .notifications-icon {
        font-size: 1.8rem;
        color: var(--aux-color);
    }

    .notifications-badge {
        width: 20px;
        height: 20px;
        font-size: 0.9rem;
        text-align: center;
        background-color: var(--danger-color);
        border-radius: 100%;
        position: absolute;
        right: 0;
        top: -15px;
    }

    .account-utilities-menu {
        flex: 1;
        align-items: center;
        position: relative;

        p {
            font-size: 1rem;
            color: var(--white);
        }
    }

    .account-utilities-user-icon {
        width: 40px;
        height: 40px;
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-dark-color);
        border-radius: 100%;
    }

    .user-icon {
        font-size: 1.5rem;
        color: var(--aux-color);
    }

    .account-utilities-submenu {
        // width: 150px;
        padding: 0.5rem 1rem;
        display: none;
        position: absolute;
        z-index: 10;
        top: 50px;
        left: -15px;
        background-color: var(--white);
        border-radius: 10px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        button {
            padding: 0.5rem;
            color: var(--grey);
            border: none;
            background: none;
            font-size: 1rem;
            cursor: pointer;
        }
    }

    .account-utilities-menu:hover .account-utilities-submenu {
        display: block;
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        flex: 0.3;

        .account-utilities-menu p {
            display: none;
        }
    }
}

