.type-label {
    width: 100px;
    display: inline-block;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 5px;
    color: var(--black);
    background: rgb(221, 250, 230);
    border: 1px dotted var(--success-color);
    text-align: center;
    text-transform: uppercase;

    &.type-label-danger {
        background: rgb(250, 221, 226);
        border: 1px dotted var(--danger-color);
    }
}