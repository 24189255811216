.balance-template-download {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px dotted #C3E3E3;
    font-size: 0.95rem;

    a {
        color: var(--aux-color);
    }
}

.upload-balance-results {
    h2 {
        line-height: 1rem;
        color: var(--primary-color);
    }

    ul {
        margin-top: 2rem;
        margin-bottom: 3rem;
        padding: 0;
        list-style-type: none;
    }

    li {
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        display: flex;
        border-radius: 10px;font-size: 0.95rem;
        background-color: rgba(#9FA3D9,0.5);

        .upload-balance-results-issue-number {
            width: 20px;
            height: 20px;
            margin-right: 0.5rem;
            display: inline-block;
            text-align: center;
            
            background-color: var(--white);
            border-radius: 100%;
        }

        .upload-balance-results-issue-message {
            flex: 0.9;
        }
    }

    .upload-balance-results-actions {
        text-align: center;
    }
}