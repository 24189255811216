
.dashboard-wrapper {
    header {
        padding: 1.4rem 0 1rem 1.2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        header .header-brand-logo {
            width: 50px;
        }
    }
}

.dashboard-container-inner {
    padding: 1rem 1.5rem;
    background-color: var(--primary-dark-color);
    min-height: 100vh;
    border-top-left-radius: 10px;
    color: var(--white);
}

.dashboard-container-content {
    max-width: 1200px;
    // height: 100vh;

    // h1 {
    //     font-size: 31px;
    // }

    // .container {
    //     height: 100vh;
    //     overflow: scroll;
    // }
}