.client-card {
    margin-bottom: 2rem;
    padding: 0.5rem 1.2rem 0.8rem 1.2rem;
    background-color: var(--white);
    border-radius: 10px;
    color: var(--light-grey);
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    .client-card-link {
        text-decoration: none;
        color: inherit;
    }

    .client-card-category {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        p {
            font-weight: bold;
            font-size: 0.9rem;
            text-transform: uppercase;
            color: var(--primary-color);
        }

        &.client-card-rnc p {
            font-size: 0.85rem;
            font-weight: normal;
        }
    }

    .client-card-edit-button {
        padding: 0;
        background: none;
        border: none;
        font-size: 1.8rem;
        cursor: pointer;
        color: #DBDDDF;

        &:hover {
            color: var(--aux-color);
        }
    }

    .client-card-details {
        display: flex;
        flex-direction: row;
        align-items: center;

        h2 {
            font-family: var(--secondaryFontAlt);
            font-size: 1.3rem;
            color: var(--grey);
            letter-spacing: 0;
            line-height: 31px;
        }
    }

    .client-card-logo {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        background-color: var(--aux-color);
        border-radius: 5px;

        &.client-card-logo-clear {
            background: none;
        }
    }

    .client-card-balance {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid #E8E9F6;
    }

    .client-card-balance-entry {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        p {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
        }
    }


    &.client-card-list-style {
        margin-bottom: 1rem;
        padding: 0rem 1.2rem 0.8rem 1.2rem;

        .client-card-logo {
            margin-top: 1rem;
        }

        .client-card-name {
            flex: 1;

            h2 {
                margin-bottom: 0;
            }

            p {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
                color: var(--primary-color)
            }
        }

        .client-card-balance {
            margin-top: 0.25rem;
            padding-top: 0.25rem;
            padding-left: 4rem;
            display: flex;
            justify-content: space-between;
        }

        .client-card-balance-entry {
            p {
                margin: 0;
            }
            
            > p {
                margin-right: 1rem;
            }

        }

        &:hover {
            transform: scale(1.02);
        }
    }

    // Breakpoints

    @media screen and (max-width: 500px) {
        &.client-card-list-style {
            .client-card-name {
                span {
                    display: none;
                }
            }
            .client-card-balance {
                padding-left: 0;
                display: block;
            }
        }
    }

    @media screen and (max-width: 400px) {
        .client-card-logo {
            display: none;
        }

        .client-card-details h2 {
            margin: 0;
        }

        .client-card-category.client-card-rnc p, .client-card-balance-entry p {
            margin-bottom: 0;
        }

        &.client-card-list-style {
            .client-card-details h2 {
                margin-top: 1rem;
            }

            .client-card-name span {
                display: none;
            }

            .client-card-balance {
                padding-left: 0;
                display: block;
            }
        }
    }
}