.switch-modal-forms-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    button {
        padding: 0.5rem;
        flex: 1;
        background: none;
        border: 2px solid var(--primary-color);
        outline: none;
        cursor: pointer;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--primary-color);

        &:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            border-right-width: 0;
        }

        &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }

        &.switch-modal-forms-tab-active {
            background-color: var(--primary-color);
            color: var(--white);
        }
    }
}