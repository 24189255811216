.inwallet-clients-entries {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted var(--aux-color);
    flex-wrap: wrap;

    .inwallet-clients-entry {
        width: 46%;
        margin: 0.25rem;
        padding: 0.25rem;
        font-size: 0.95rem;
        display: inline-block;
        background-color: rgba(#9FA3D9, 0.75);
        border-radius: 10px;
        text-align: center;
    }

}

.no-clients-inwallet-message {
    padding: 4rem 25%;
    background-color: rgba(#9FA3D9, 0.25);
    border-radius: 15px;
    text-align: center;

    .no-clients-inwallet-message-icon {
        margin: 0 auto 1rem auto;
        font-size: 4rem;
        display: block;
        color: rgba(#9FA3D9, 0.75);
    }
}