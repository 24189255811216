.client-balance-sheet {
    margin-bottom: 1.5rem;
    background: var(--white);
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.10);
    border-radius: 10px;
    color: var(--grey);

    h2 {
        margin-left: 1rem;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 0.9rem;
        text-transform: uppercase;
        color: var(--primary-color);
    }

    p {
        margin: 0;
        font-size: 0.7rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    .balance-sheet-entries {
        padding: 0.5rem 1rem 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid rgba(#9FA3D9, 0.24);
        border-right: 2px solid rgba(#9FA3D9, 0.24);
    }

    .balance-sheet-entry {
        margin-bottom: 1rem;
    }

    .balance-amount {
        font-family: var(--secondaryFontAlt);
        font-size: 1.3rem;
        color: var(--secondary-color);
    }

    .balance-amount-prev {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: right;
    }

    .balance-sheet-admin, .balance-sheet-admin-prev {
        padding: 1.5rem 2rem;
    }

    .balance-sheet-admin {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-right: 2px solid rgba(#9FA3D9, 0.24);

        .balance-amount {
            color: var(--grey);
        }
    }

    .balance-sheet-admin-prev {
        .balance-amount {
            color: var(--grey);
        }
    }

    .balance-amount-danger {
        color: var(--danger-color);
    }

    
    // Breakpoints

    @media screen and (max-width: 768px) {
        .balance-sheet-admin, .balance-sheet-admin-prev {
            text-align: right;
        }
    }
}
