.client-select-heading {
    margin-top: 1rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    flex: 1;

    h1 {
        margin: 0;
        font-size: 1.5rem;
        flex: none;
    }

    .client-select-icon {
        margin-left: 0.5rem;
        font-size: 2rem;
        color: var(--aux-color);
    }

    .client-select-heading-logo {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        background-color: var(--aux-color);
        border-radius: 5px;

        &.client-select-heading-logo-clear {
            background: none;
        }
    }
}