.table-list {

    th {
        padding: 0.25rem;
        background-color: rgba(black,0.2);
    }

    .table-entry.table-changes {

        .log-customer {
            width: 25%;
        }

        .log-message {
            width: 50%;
        }

        .log-date {
            width: 10%;
        }

        .log-action {
            width: 5%;
        }

        .log-message-changes {
            display: flex;
            justify-content: space-between;

            p {
                margin: 0;
                color: var(--aux-color);
            }
        }

        .log-action button {
            padding: 0;
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            color: #DBDDDF;
            opacity: 0.5;
    
            &:hover {
                opacity: 1;
            }
        }

        .log-message-changes {
            line-height: 1.5rem;
            strong {
                color: white;
            }
        }

    }
}