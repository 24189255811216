.section-header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    h1 {
        margin-left: 0.5rem;
        margin-bottom: 2rem;
        flex: 1;
        font-family: var(--secondaryFont);
        font-size: 1.94rem;
        color: var(--white);
        letter-spacing: 0;
    }

    h2 {
        margin: 0;
        margin-left: 0.5rem;
        flex: 1;
        font-size: 1.3rem;
        font-family: var(--secondaryFont);
        color: var(--white);
    }

    .section-header-no-margins {
        margin-bottom: 0;
    }

    .section-header-subheading {
        font-size: 1rem;
        color: var(--aux-color);
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        flex-direction: column;

        h2 {
            margin-bottom: 1.5rem;
        }
    }

    @media screen and (max-width: 360px) {
        h1 {
            font-size: 1.5rem;
        }

        .section-header-subheading {
            display: block;
            margin-bottom: 1rem;
        }
    }

}