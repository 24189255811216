.status-label {
    min-width: 75px;
    display: inline-block;
    padding: 0.2rem 0;
    font-size: 0.75rem;
    border-radius: 5px;
    color: var(--black);
    background: rgb(221, 250, 230);
    border: 1px dotted var(--success-color);
    text-transform: uppercase;
    text-align: center;

    &.status-label-danger {
        background: rgb(250, 221, 226);
        border: 1px dotted var(--danger-color);
    }
}