.add-user-button {
    height: 42px;
    margin-right: 1rem;
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--white);
    vertical-align: middle;

    p {
        margin: 0;
        margin-left: 0.5rem;
        display: inline-block;
        font-size: 1rem;
        font-weight: bold;
    }

    &:hover {
        background-color: darken(#39AC4F, 10%);
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        margin: 0;
        margin-bottom: 1.5rem;
    }
}