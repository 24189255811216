.auth-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    background-image: url('../../assets//i/bg-login.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-form-container {
    max-width: 450px;
    flex: 0.4;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: var(--main-gradient);

    img {
        margin-top: auto;
        margin-bottom: 2rem;
    }

    .btn-plain {
        margin-bottom: 3rem;
        margin-right: 12%;
        align-self: flex-end;
    }

    .btn {
        min-width: 50%;
        align-self: center;
    }

    .field {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .field input {
        margin: 0 12%;
    }

    .field span {
        margin-right: 12%;
        text-align: right;
    }

    h1 {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 1.5rem;
        color: var(--white);
    }

    .form-error-message {
        margin-right: 12%;
        margin-left: 12%;
    }

    // Breakpoints

    @media screen and (max-width: 768px) {
        max-width: none;
        flex: 1;
        margin-right: 0;
    }
}

.auth-description {
    margin: 0 12% 2rem 12%;
    font-size: 14px;
    color: var(--white);
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
}

img {
    max-width: 100%;
}

.btn-plain {
    font-size: 1rem;
    background: none;
    border: none;
    text-decoration: underline;
    color: var(--white);
    cursor: pointer;
}

.btn-danger {
    width: 100%;
    margin-top: 1rem;
    padding: 0;
    font-weight: normal;
    font-size: 1rem;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    background: none;
    color: var(--danger-color);
    cursor: pointer;
}